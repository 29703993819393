import { Typography } from '@/components/Typography';
import './GitOpsBenefits.scss';
import { gitOpsPage } from '@/utils/contentData/gitOpsPage';

const { title, items: listOfCases } = gitOpsPage.gitOpsBenefits;

const GitOpsUseCases = () => (
  <div
    className={'git-ops-use-cases content--max-width'}
    id={'git-ops-use-cases'}
  >
    <Typography
      className={'git-ops-use-cases__title'}
      component={'h2'}
      typographyKind={'header-secondary-large-bold'}
    >
      {title}
    </Typography>
    <div className={'container-fluid'}>
      <div className={'row'}>
        {(listOfCases || []).map(({ description, icon: Icon }, index) => (
          <div
            key={description}
            className={'col-sm-12 col-md-6 col-lg-6 col-xl-4'}
          >
            <div
              className={
                'flex-row align-center git-ops-use-cases__item-description-wrapper'
              }
            >
              <div className="git-ops-benefits_svg-wrapper">
                <Icon className="git-ops-benefits__image" />
              </div>
              <Typography
                className={'git-ops-use-cases__item-description'}
                component={'p'}
                typographyKind={'text-primary-large-bold'}
              >
                {description}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default GitOpsUseCases;
