import { en } from '@/languages/en';
import {
  CDIcon,
  CloudIcon,
  CycleCircleIcon,
  DocumentIcon,
  DotsGridIcon,
  EyeIcon,
  EyePlusIcon,
  HourGlassIcon,
  KubernetesIcon,
  LineIcon,
  LockIcon,
  MagnifyingGlassIcon,
  OppositeArrowsIcon,
  PersonIcon,
  TickIcon
} from '@/static/assets/icons';
import { CONTACT_US_PAGE_URL } from '@/utils/urlUi';

const language = en;

export const gitOpsPage = {
  header: {
    title: language.gitOpsPage.header.title,
    subTitle: language.gitOpsPage.header.subTitle,
    description: language.gitOpsPage.header.description,
    image: {
      name: 'git-ops-header-image.png',
      alt: 'git ops header'
    }
  },
  aboutGitOps: {
    title: language.gitOpsPage.aboutGitOps.title,
    descriptionOne: language.gitOpsPage.aboutGitOps.descriptionOne,
    descriptionTwo: language.gitOpsPage.aboutGitOps.descriptionTwo,
    descriptionThree: language.gitOpsPage.aboutGitOps.descriptionThree,
    image: {
      name: 'git-ops-schema.png',
      alt: 'git ops schema'
    }
  },
  gitOpsUseCases: {
    title: language.gitOpsPage.useCases.title,
    items: [
      {
        description: language.gitOpsPage.useCases.items.continuousDelivery,
        icon: CDIcon
      },
      {
        description: language.gitOpsPage.useCases.items.applyingProgressive,
        icon: CloudIcon
      },
      {
        description: language.gitOpsPage.useCases.items.rollingInfrastructure,
        icon: KubernetesIcon
      },
      {
        description: language.gitOpsPage.useCases.items.deployingMultiple,
        icon: DotsGridIcon
      },
      {
        description: language.gitOpsPage.useCases.items.quickDisaster,
        icon: CycleCircleIcon
      },
      {
        description: language.gitOpsPage.useCases.items.synchronizingSecrets,
        icon: LockIcon
      },
      {
        description: language.gitOpsPage.useCases.items.detectingConfiguration,
        icon: MagnifyingGlassIcon
      },
      {
        description: language.gitOpsPage.useCases.items.introducingSecure,
        icon: OppositeArrowsIcon
      }
    ]
  },
  gitOpsBenefits: {
    title: language.gitOpsPage.benefits.title,
    items: [
      {
        description: language.gitOpsPage.benefits.items.velocity,
        icon: HourGlassIcon
      },
      {
        description: language.gitOpsPage.benefits.items.developer,
        icon: PersonIcon
      },
      {
        description: language.gitOpsPage.benefits.items.rollout,
        icon: LineIcon
      },
      {
        description: language.gitOpsPage.benefits.items.auditability,
        icon: EyeIcon
      },
      {
        description: language.gitOpsPage.benefits.items.documented,
        icon: DocumentIcon
      },
      {
        description: language.gitOpsPage.benefits.items.observabiliity,
        icon: EyePlusIcon
      },
      {
        description: language.gitOpsPage.benefits.items.stability,
        icon: TickIcon
      }
    ]
  },
  faq: {
    title: language.gitOpsPage.faq.title,
    accordion: [
      {
        id: language.gitOpsPage.faq.accordion.accordionOne.id,
        title: language.gitOpsPage.faq.accordion.accordionOne.title,
        benefitsList: [
          {
            id: language.gitOpsPage.faq.accordion.accordionOne.benefits
              .benefitOne.id,
            title:
              language.gitOpsPage.faq.accordion.accordionOne.benefits.benefitOne
                .title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionOne.benefits
              .benefitTwo.id,
            title:
              language.gitOpsPage.faq.accordion.accordionOne.benefits.benefitTwo
                .title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionOne.benefits
              .benefitThree.id,
            title:
              language.gitOpsPage.faq.accordion.accordionOne.benefits
                .benefitThree.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionOne.benefits
              .benefitFour.id,
            title:
              language.gitOpsPage.faq.accordion.accordionOne.benefits
                .benefitFour.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionOne.benefits
              .benefitFive.id,
            title:
              language.gitOpsPage.faq.accordion.accordionOne.benefits
                .benefitFive.title
          }
        ]
      },
      {
        id: language.gitOpsPage.faq.accordion.accordionTwo.id,
        title: language.gitOpsPage.faq.accordion.accordionTwo.title,
        benefitsList: [
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitOne.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits.benefitOne
                .title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitTwo.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits.benefitTwo
                .title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitThree.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits
                .benefitThree.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitFour.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits
                .benefitFour.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitFive.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits
                .benefitFive.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionTwo.benefits
              .benefitSix.id,
            title:
              language.gitOpsPage.faq.accordion.accordionTwo.benefits.benefitSix
                .title
          }
        ]
      },
      {
        id: language.gitOpsPage.faq.accordion.accordionThree.id,
        title: language.gitOpsPage.faq.accordion.accordionThree.title,
        benefitsList: [
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitOne.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitOne.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitTwo.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitTwo.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitThree.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitThree.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitFour.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitFour.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitFive.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitFive.title
          },
          {
            id: language.gitOpsPage.faq.accordion.accordionThree.benefits
              .benefitSix.id,
            title:
              language.gitOpsPage.faq.accordion.accordionThree.benefits
                .benefitSix.title
          }
        ]
      }
    ]
  },
  cta: {
    titleBottom: language.gitOpsPage.cta.title.titleBottom,
    titleTop: language.gitOpsPage.cta.title.titleTop,
    button: {
      text: language.gitOpsPage.cta.button,
      link: CONTACT_US_PAGE_URL,
      isExternal: false
    }
  }
};
