import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import { Typography } from '@/components/Typography';
import { trackEvent } from '@/helpers/analytics';
import {
  GitOpsUseCases,
  Header,
  AboutGItOps,
  Faq,
  GitOpsBenefits
} from '@/sections/GitOps';
import { gitOpsPage } from '@/utils/contentData/gitOpsPage';
import { seoConfiguration } from '@/utils/seo-config';

import './gitops.scss';

const { titleBottom, titleTop } = gitOpsPage.cta;
const {
  title: seoTitle,
  description: seoDescription,
  image: seoImage
} = seoConfiguration.gitOps;

const GitOpsPage = () => {
  return (
    <Layout title={seoTitle} description={seoDescription} image={seoImage}>
      <div className="gitops-page">
        <Header />
        <div className="no-global-padding about-gitops">
          <AboutGItOps />
        </div>
        <div className="no-global-padding gitops-use-cases">
          <div className="padding-global">
            <GitOpsUseCases />
            <GitOpsBenefits />
          </div>
        </div>
        <Faq />
        <div className="no-global-padding cta-wrapper">
          <section>
            <Typography
              component="h2"
              typographyKind="header-primary-medium-bold"
              color="default"
              size={40}
              lineHeight={42}
            >
              {titleTop}
              <br />
              {titleBottom}
            </Typography>
            <div className="buttons">
              <Button
                link={content.cta.pricingAndPlans.url}
                additionalClass="pricing-cta button-hover-color--primary-subtle"
              >
                {content.cta.pricingAndPlans.text}
              </Button>
              <Button
                link={content.cta.trial.url}
                additionalClass="trial-cta button-hover-color--secondary"
                onClickFunc={() => trackEvent('akuity_io_to_cloud')}
                isExternalLink
              >
                {content.cta.trial.text}
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

const content = {
  cta: {
    header: 'Ready to Get Started with Akuity?',
    subheader: 'Contact our team to learn more about our products and services',
    pricingAndPlans: {
      text: 'Pricing & Plans',
      url: '/pricing'
    },
    trial: {
      text: 'Start a Free Trial',
      url: '/signup'
    }
  }
};

export default GitOpsPage;
