import { Button } from '@/components/Button';
import LazyImage from '@/components/LazyImage';
import PageHeader from '@/components/PageHeader';
import { Typography } from '@/components/Typography';
import { gitOpsPage } from '@/utils/contentData/gitOpsPage';
import './GitOpsHeader.scss';

const { description, title, subTitle, image } = gitOpsPage.header;

const TextDescription = () => (
  <Typography
    component={'p'}
    typographyKind={'text-secondary-extra-large'}
    margin={[10, 0]}
    size={20}
    lineHeight={32}
  >
    {description}
  </Typography>
);

const GitOpsHeader = () => (
  <div className={'gitops-header content--max-width'} id={'gitops'}>
    <PageHeader
      textPrimary={{
        text: title,
        size: 48,
        lineHeight: 60,
        typographyKind: 'header-primary-medium-bold',
        color: 'akuity-purple'
      }}
      textSecondary={{
        text: subTitle,
        size: 24,
        lineHeight: 40,
        typographyKind: 'header-secondary-medium-small',
        margin: [2, 0],
        className: 'gitops-header-heading-small'
      }}
      textDescription={{
        text: (
          <>
            <TextDescription />
            <Button
              link="/talk-to-sales"
              additionalClass="talk-to-sales-btn button-hover-color--secondary"
            >
              Talk to Sales
            </Button>
          </>
        )
      }}
      image={
        <LazyImage imageName={image.name} alt={image.alt} loading={'eager'} />
      }
      classImageColumn={
        'gitops-header__image-wrapper col-lg-12 col-xl-5 no-gap position-relative margin-top-auto margin-bottom-auto'
      }
      classTextColumn={
        'gitops-header__page-header__column-text col-lg-12 col-xl-7 page-header__text-colum--padding'
      }
    />
  </div>
);

export default GitOpsHeader;
