import Accordion from '@/components/Accordion';
import { Typography } from '@/components/Typography';
import './GitOpsFaq.scss';
import { gitOpsPage } from '@/utils/contentData/gitOpsPage';
const { accordion, title } = gitOpsPage.faq;

const GitOpsFaq = () => (
  <div className={'gitops-faq content--max-width no-select'}>
    <Typography
      component={'h2'}
      typographyKind={'header-secondary-large-bold'}
      className={'gitops-faq__header'}
      margin={[16, 0]}
    >
      {title}
    </Typography>
    <div className="accordion">
      {accordion.map(({ id, title, benefitsList }) => (
        <Accordion key={id} title={title} benefitsList={benefitsList} />
      ))}
    </div>
  </div>
);

export default GitOpsFaq;
