import { useState } from 'react';
import { Typography } from '../Typography';
import { AccordionItemType } from './type';
import {
  GitOpsFaqChevronUpIcon as ChevronUpIcon,
  GitOpsFaqChevronDownIcon as ChevronDownIcon
} from '@/static/assets/icons';
import './Accordion.scss';

const Accordion = ({ title, content, benefitsList }: AccordionItemType) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className="accordion-title-wrapper"
        onClick={() => setIsActive(!isActive)}
      >
        <Typography
          typographyKind="text-primary-large-bold"
          component="p"
          color="secondary-sixty"
          className="accorion-title"
        >
          {title}
        </Typography>
        <div>{isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
      </div>
      {isActive && (
        <Typography
          typographyKind="text-primary-medium-regular"
          component="p"
          className="accordion-content"
        >
          {content && content}
          {benefitsList && (
            <ul className="accorion-benefits-list">
              {benefitsList.map(({ id, title }) => (
                <li key={id}>{title}</li>
              ))}
            </ul>
          )}
        </Typography>
      )}
    </div>
  );
};

export default Accordion;
