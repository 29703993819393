import { Typography } from '@/components/Typography';
import GitOpsSchema from '@/static/assets/images/GitOpsPage/git-ops-schema.png';
import { gitOpsPage } from '@/utils/contentData/gitOpsPage';
import './AboutGItOps.scss';

const {
  aboutGitOps: {
    title,
    descriptionOne,
    descriptionThree,
    descriptionTwo,
    image
  }
} = gitOpsPage;

const AboutGItOps = () => {
  return (
    <div className={'gitOps-aboutGitOps content--max-width'}>
      <Typography
        component={'h2'}
        typographyKind={'header-secondary-large-bold'}
        className={'git-ops-aboutGitOps__title'}
      >
        {title}
      </Typography>

      <Typography
        margin={[10, 0]}
        component={'p'}
        typographyKind={'text-secondary-large-medium'}
        size={18}
        lineHeight={32}
        className="gitOps-aboutGitOps_description"
      >
        {descriptionOne}
      </Typography>
      <div className="gitops-description-illustration">
        <img src={GitOpsSchema} alt={image.alt} />
      </div>
      <div className="boxes">
        <Typography
          component={'p'}
          margin={[10, 0]}
          typographyKind={'text-secondary-large-medium'}
          size={18}
          lineHeight={32}
          className="col-sm-12 col-md-5 col-lg-5"
        >
          {descriptionTwo}
        </Typography>
        <Typography
          component={'p'}
          typographyKind={'text-secondary-large-medium'}
          size={18}
          lineHeight={32}
          className="col-sm-12 col-md-5 col-lg-5"
        >
          {descriptionThree}
        </Typography>
      </div>
    </div>
  );
};

export default AboutGItOps;
